
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import { IPriceTag } from "@/types/price_tag";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //price_tag
  @Action("price_tag/adminGet")
  public getPriceTag!: (price_tag_id: number) => void;

  @Getter("price_tag/single")
  public price_tag!: IPriceTag;

  @Mutation("price_tag/clear")
  public clear!: () => void;

  // fields
  public price_tag_id = 0;

  public items: { text: string; icon: string; to: string }[] = [];

  public async created() {
    this.price_tag_id = Number(this.$route.params.price_tag_id);

    this.items = [
      {
        text: "顧客品番",
        icon: "mdi-numeric",
        to: `/admin/price_tag/${this.price_tag_id}`
      },
      {
        text: "サプライヤ品番",
        icon: "mdi-numeric",
        to: `/admin/price_tag/${this.price_tag_id}/price_tag_supplier`
      }
    ];

    this.clear();
    await Promise.all([this.getPriceTag(this.price_tag_id)]);
  }
}
